@import "../../styles/ui-const.scss";
@import "../../styles/ui-anim.scss";

.App {
  text-align: center;
}

.movie-wrapper {
  filter: blur(7px);
  transform: scale(1.1);
  width: 110vw;
  height: 110vh;
}

.content-base {
  position: absolute;
  width: 100vw;
  height: 100vh;
  z-index: 1;

  .categories {
    width: 100%;
    height: 10%;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    place-items: center;

    .category-btn {
      width: 300px;
      height: auto;
      font-family: "Electrolize", sans-serif;
      letter-spacing: 0.1rem;
      font-size: 1.5rem;
      color: $white;
      transition: all 250ms ease-in-out;

      cursor: pointer;
      filter: contrast(2) saturate(5);

      &:hover {
        border: 1px solid $white;
        padding: 10px;
        box-shadow: 0 0 25px $white, 0 1px 5px $black;
        color: rgba($black, 0.7);
        background: $white;
        letter-spacing: 0.4rem;
        font-size: 2rem;
      }
    }
  }

  .content-active {
    width: 90%;
    height: 80%;
    margin-left: 5%;
    margin-top: 1.5%;
    color: $white;

    ul {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      margin: 0;
      padding: 0;
      width: 600px;
      height: 150px;
    }

    ul li {
      list-style: none;
      position: absolute;
      width: 200px;
      height: 200px;
      background: transparent;
      border: 2px solid rgba(255, 255, 255, 0.3);
      margin: -100px;
      transform: rotate(45deg);
      transition: all 1s linear;
      overflow: hidden;
      opacity: 1;

      &:hover {
        border-color: $green !important;

        img {
          transform: scale(1.5) rotate(-45deg);
          opacity: 1;
        }

        .project-title {
          opacity: 1;
        }
      }
    }

    ul li img {
      width: 100%;
      height: 100%;
      transform: scale(1.75) rotate(-45deg);
      transition: all 0.5s linear;
      position: relative;
      transform-origin: center;
      opacity: 0.5;
    }

    .project-title {
      display: block;
      font-family: "Electrolize", sans-serif;
      font-size: 1rem;
      color: $white;
      text-shadow: 0px 4px 2px rgba($black, 0.5);
      transition: all 0.5s linear;
      position: relative;
      top: -55%;
      left: 0;
      transform-origin: center;
      transform: rotate(-45deg);
      opacity: 0;
    }

    ul li:hover {
      opacity: 1;
    }

    ul li.item1 {
      top: 0;
      left: 0;
    }

    ul li.item2 {
      bottom: 0;
      left: 25%;
    }

    ul li.item3 {
      top: 0;
      left: 50%;
    }

    ul li.item4 {
      bottom: 0;
      left: 75%;
    }

    ul li.item5 {
      top: 0;
      left: 100%;
    }

    ul li.item6 {
      top: 195%;
      left: 50%;
    }
  }

  .project {
    height: 88vh;
    width: 50vw;
    left: 25%;
    position: relative;
    font-family: "Electrolize", sans-serif;
    letter-spacing: 0.1rem;
    font-size: 1.5rem;
    color: $white;
    text-shadow: 0px 4px 2px rgba($black, 0.5);
    padding-top: 30px;
    display: grid;
    place-items: center;
    grid-template-rows: 45% 25% 10%;

    .technologies {
      padding-top: 10px;

      span:nth-of-type(1) {
        display: block;
      }
    }
  }

  .contact {
    font-family: "Electrolize", sans-serif;
    letter-spacing: 0.1rem;
    font-size: 1.5rem;
    color: $white;
    text-shadow: 0px 4px 2px rgba($black, 0.5);
    padding-top: 15%;

    span {
      display: block;
      padding: 10px;
    }

    span:nth-of-type(1) {
      padding-bottom: 30px;
    }
  }

  .tilted {
    width: 100vw;
    height: 75vh;
  }
}

.carousel {
  max-width: 900px;
  max-height: 500px;
  margin: auto;
}

.slide img {
  min-height: 500px;
}

// iPhone 5/6/7/8
@media only screen and (max-device-width: 736px) and (max-device-height: 414px) and (orientation: landscape) {
  .content-base {
    .content-active {
      transform: scale(0.5);

      .project-title {
        opacity: 1;
      }
    }

    .project {
      font-size: 0.7rem;
      grid-template-rows: 35% 10% 50%;
    }

    .contact {
      padding-top: 0;
    }
  }

  .carousel {
    max-width: 225px;
    max-height: 125px;
  }

  .slide img {
    min-height: 125px;
    min-width: 125px;
  }
}

// Pixel 2 and other similar sized Android phones
@media only screen and (max-device-width: 823px) and (max-device-height: 411px) and (orientation: landscape) {
  .content-base {
    .content-active {
      transform: scale(0.5);

      .project-title {
        opacity: 1;
      }
    }

    .project {
      font-size: 0.7rem;
      grid-template-rows: 35% 10% 45%;
    }

    .contact {
      padding-top: 0;
    }
  }

  .carousel {
    max-width: 225px;
    max-height: 125px;
  }

  .slide img {
    min-height: 125px;
    min-width: 125px;
  }
}

// Tablets
@media (min-width: 1024px) and (max-device-height: 1024px) {
  .content-base {
    .content-active {
      .project-title {
        opacity: 1;
      }
    }

    .project {
      font-size: 1rem;
      grid-template-rows: 35% 15% 20%;
    }
  }

  .carousel {
    max-width: 450px;
    max-height: 250px;
  }

  .slide img {
    min-height: 250px;
  }
}

@media (max-width: 1024px) and (max-device-height: 1366px) {
  .content-base {
    .content-active {
      .project-title {
        opacity: 1;
      }
    }

    .project {
      grid-template-rows: 35% 15% 15%;
    }
  }
}

// 720p
@media (min-width: 1280px) and (max-device-height: 720px) {
  .content-base {
    .project {
      font-size: 1rem;
      grid-template-rows: 35% 15% 20%;
    }
  }

  .carousel {
    max-width: 450px;
    max-height: 250px;
  }

  .slide img {
    min-height: 250px;
  }
}

// 1440p
@media (min-width: 2560px) {
  .content-base {
    .project {
      grid-template-rows: 40% 10% 15%;
    }
  }
}

// 1440p Ultrawide
@media (min-width: 3440px) {
  .content-base {
    .project {
      grid-template-rows: 40% 10% 15%;
    }
  }
}

// 4K
@media (min-width: 3840px) {
  .content-base {
    .project {
      font-size: 3rem;
      grid-template-rows: 25% 10% 20%;
    }
  }
}
